import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../../components/common/layout'
import Seo from '../../components/common/seo'
import Container from '../../components/common/container'
import media from '../../components/css/media'
import Carousel from '../../components/common/carousel'
import { getBannerByType } from '../../dataManipulation/banners'

const PromotionsWrapper = styled.div`
  padding: 2rem 0;
`

const BannerPlaceholder = styled.section`
  background-color: #1e1e1e;
  border-radius: 8px;
  height: 240px;
  overflow: hidden;

  ${media.desktop`
    height: 320px;

    .container {
      padding: 0 2rem;
    }
  `};
`

const HorizontalMenu = styled.ul`
  margin: 1rem 0;
  padding: 0;
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;

  ${media.widescreen`
    width: 700px;
    margin: 1rem auto;
    justify-content: center;
  `};
`

const ListItem = styled.li`
  text-align: center;
  padding: 1rem;
  margin: 0;
  min-width: 80px;
  list-style: none;
  cursor: pointer;
  text-transform: capitalize;

  &:hover,
  &.active {
    border-bottom: 3px solid #000;
    padding-bottom: calc(1rem - 3px);
  }
`

const PromotionsHolder = styled.section`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(calc(50% - 20px), 1fr));
  `};

  ${media.widescreen`
    grid-template-columns: repeat(auto-fill,minmax(calc(33.33333% - 20px), 1fr));
  `};
`

const Promo = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const PromoImgWrapper = styled.div`
  height: 220px;
  margin: 0 0 -0.2rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const PromoDetails = styled.div`
  background-color: #000;
  min-height: 135px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
`

const PromoTitle = styled.h2`
  color: #fff;
  font-size: 1.2em;
  line-height: 25px;
  margin: 0 0 0.25rem;
  font-weight: 400;
`

const PromoTag = styled.span`
  color: #fff;
  font-size: 0.6em;
  line-height: 17px;
  text-transform: uppercase;
  border-radius: 2px;
  position: absolute;
  bottom: 1rem;
  padding: 0.1rem 0.5rem;
  text-align: center;

  ${(props) => {
    switch (props.bg.replace(' ', '-')) {
      case 'casino':
      default:
        return `
          background-color: #6bc89b;
        `
      case 'live-casino':
        return `
          background-color: #900000;
        `
      case 'sports':
        return `
          background-color: #218afb;
        `
      case 'virtual-sports':
        return `
          background-color: #fad749;
        `
    }
  }}
`

const PromotionsPage = ({ pageContext: { promotionsSlug } }) => {
  const data = useStaticQuery(graphql`
    {
      promotionsApi {
        values {
          promotions {
            slug
            image {
              alt
              height
              width
              url
              sizes {
                medium_large
                thumbnail
                optimized {
                  src
                }
              }
              optimized {
                src
              }
            }
            title
            type
          }
          lang
        }
      }
      bannersApi {
        values {
          lang
          banners {
            activeFrom
            activeFromStr
            activeTo
            activeToStr
            backgroundColor
            content
            vertical
            ctaLoggedIn {
              target
              title
              url
            }
            ctaLoggedOut {
              target
              title
              url
            }
            imageBackground {
              url
              optimized {
                src
              }
            }
            imageBackgroundMobile {
              url
              optimized {
                src
              }
            }
          }
        }
      }
    }
  `)

  const {
    bannersApi: { values },
    promotionsApi: { values: promotionsData },
  } = data

  const intl = useIntl()

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].banners
  const banners = dataExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].banners
    : []

  const promotionsExistForLocale = promotionsData.some(
    (row) => row.lang === intl.locale
  )
  const promotionsExistForPage =
    promotionsExistForLocale &&
    promotionsData.filter((row) => row.lang === intl.locale)[0].promotions
  const promos = useMemo(
    () =>
      promotionsExistForPage
        ? promotionsData.filter((row) => row.lang === intl.locale)[0].promotions
        : [],
    [intl.locale, promotionsData, promotionsExistForPage]
  )

  let promotionTypes = ['all']
  promos.forEach((promo) => {
    if (!promotionTypes.includes(promo.type)) promotionTypes.push(promo.type)
  })

  const [tab, setTab] = useState('all')
  const [promotions, setPromotions] = useState(promos)

  useEffect(() => {
    const filteredPromotions =
      tab === 'all' ? promos : promos.filter((p) => p.type === tab)
    setPromotions(filteredPromotions)
  }, [tab, promos])

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: `seo.promotions_title` })}
        description={intl.formatMessage({ id: `seo.promotions_description` })}
      />
      <Container>
        <PromotionsWrapper>
          <BannerPlaceholder>
            <Carousel
              slides={getBannerByType(banners, intl.locale, 'promotions')}
            />
          </BannerPlaceholder>
          <HorizontalMenu>
            {promotionTypes.map((type, i) => {
              return (
                <ListItem
                  key={i}
                  onClick={() => setTab(type.toLowerCase())}
                  className={`${tab === type.toLowerCase() ? 'active' : ''}`}
                >
                  {intl.formatMessage({
                    id: `promotions.${type.toLowerCase()}`,
                  })}
                </ListItem>
              )
            })}
          </HorizontalMenu>
          <PromotionsHolder>
            {promotions.map((promo, i) => {
              return (
                <Promo
                  key={i}
                  to={`/${intl.locale}/${promotionsSlug}/${promo.slug}`}
                >
                  <PromoImgWrapper>
                    {promo.image && (
                      <picture>
                        <source
                          srcSet={`${
                            promo.image.sizes?.optimized?.src ||
                            promo.image.sizes.medium_large
                          }`}
                          media='(max-width: 480px)'
                        />
                        <source
                          srcSet={`${
                            promo.image.sizes?.optimized?.src ||
                            promo.image.sizes.medium_large
                          }`}
                          media='(max-width: 768px)'
                        />
                        <img
                          width={promo.image.width}
                          height={promo.image.height}
                          loading='lazy'
                          src={`${
                            promo.image?.optimized?.src || promo.image.url
                          }`}
                          alt={`${promo.image.alt}`}
                        />
                      </picture>
                    )}
                  </PromoImgWrapper>
                  <PromoDetails>
                    <PromoTitle>{promo.title}</PromoTitle>
                    <PromoTag bg={promo.type}>{promo.type}</PromoTag>
                  </PromoDetails>
                </Promo>
              )
            })}
          </PromotionsHolder>
        </PromotionsWrapper>
      </Container>
    </Layout>
  )
}

export default PromotionsPage
